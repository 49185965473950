let init = () => {
    let $toggle = document.querySelector(".-js-search-toggle")
    let $input = document.querySelector(".-js-search-input")

    if (!$toggle || !$input) {
        return
    }

    $toggle.addEventListener("click", function() {
        setTimeout(() => {
            $input.focus()
            let value = $input.value
            $input.value = null
            $input.value = value
        }, 100)
    })
}

export default { init }
