import Flickity from "flickity"
import "flickity/css/flickity.css"

let init = () => {
    let $components = document.querySelectorAll(".-js-section-products-list:not(.-js-upgraded)")

    // Set upgraded class
    for (let $component of $components) {
        let $items = $component.querySelectorAll(".-js-section-products-list-item")

        if ($items.length < 2) {
            return
        }

        $component.classList.add("-js-upgraded")

        let options = {
            cellSelector: ".-js-section-products-list-item",
            groupCells: true,
            contain: true,
            selectedAttraction: 0.01,
            friction: 0.15,
            pageDots: false,
        }

        new Flickity($component, options)
    }
}

export default { init }
