import Flickity from "flickity"
import "flickity/css/flickity.css"

let init = () => {
    let $slider = document.querySelector(".-js-section-slider")

    if (!$slider) {
        return
    }

    $slider.classList.add("-js-upgraded")

    if ($slider.querySelectorAll(".-js-section-slider-item").length < 2) {
        return
    }

    let options = {
        cellSelector: ".-js-section-slider-item",
        autoPlay: 20000,
        selectedAttraction: 0.01,
        friction: 0.15,
        wrapAround: true,
        setGallerySize: false,
        pageDots: false,
    }

    new Flickity($slider.querySelector(".-js-section-slider-items"), options)
}

export default { init }
