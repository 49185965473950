import "./theme.styl"

import { Ripple, Button, Textfield, Select } from "@framework"
import DefaultLayout from "@/layouts/default/default.js"
import SectionSlider from "@/components/section/section-slider.js"
import SectionProductsList from "@/components/section/section-products-list.js"

// Init before DOMContentLoaded
DefaultLayout.init()

// Init after DDOMContentLoadedOM
document.addEventListener("DOMContentLoaded", () => {
    Button.init()
    Ripple.init()
    Textfield.init()
    Select.init()
    SectionSlider.init()
    SectionProductsList.init()
})
